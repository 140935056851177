























































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'Chem51LBProject1OIProcedure',
  components: {TableInput},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        rows: [
          {
            chemicalName: '',
            physicalStates: '',
            mp: '',
            bp: '',
          },
        ],
        supplyList: '',
      },
      attachments: [] as File[],
      columns: [
        {label: 'Compound Name', component: STextarea, prop: 'chemicalName', width: '36%'},
        {
          label: 'Physical State at RT (s, l, g, aq)',
          component: STextarea,
          prop: 'physicalStates',
          width: '28%',
        },
        {label: 'Melting point (°C)', component: STextarea, prop: 'mp', width: '18%'},
        {label: 'Boiling point (°C)', component: STextarea, prop: 'bp', width: '18%'},
      ],
    };
  },
  computed: {
    attachments(): File[] {
      return [...this.attachments];
    },
  },
  methods: {},
});
